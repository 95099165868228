import React, { useEffect } from 'react';
import Layout from '../components/Layout';
import RonakHero from '../components/RonakPipaliya/RonakHero';

import allApiData from "../api";
import PortfolioCom from '../components/Home/PortfolioCom';
import allData from '../allData';
import IndustriesCom from '../components/Home/IndustriesCom';

const ronakExpertisesData = allData.ronakExpertisesData;

const userData = {
    name: "Devant Rupani",
    designation: "Game Consultant",
    image: '../assets/img/ronak-pipaliya/devant-rupani.png',
    tag: `devant-rupani`,
    phones: ['+1 (551) 344-6173'],
    // emails: ['ronak@vasundhara.io', 'ronak.vasundhara@gmail.com'],
    emails: ['devant@vasundhara.io'],
    linkedin: 'https://www.linkedin.com/in/devant-rupani-5265b6155/',
    // facebook='https://www.facebook.com/ronak.pipaliya.92',
    skype: 'https://join.skype.com/invite/ThIk9HM34qQg',
    behance: `https://www.behance.net/vasundharainfotech`,
    whatsapp: `https://wa.me/+15513446173`,
    description: [
        `Devant Rupani is a pivotal figure in game development and consultancy at Vasundhara Infotech.`,
        `Specializes in turning client visions into engaging 3D mobile games.`,
        `Expert in gathering and interpreting client requirements for precise game design translation.`,
        `Acts as a crucial communicator between clients and the technical team.`,
        `Guides projects from concept to completion, ensuring strategic alignment.`,
        `Conducts strategic planning and requirement analysis for game development.`,
        `Committed to delivering immersive and captivating gaming experiences.`,
        `Aims to exceed client and gamer expectations in the competitive mobile gaming market.`,
        `Recognized for dedication and passion in advancing the gaming industry.`
    ]
}

function DevantRupani() {

    const tabNameData = ['Game Development'];
        useEffect(() => {
            allApiData.aosinit();
    }, []);

    return (
        <Layout footernone={false} padding={true}>
            <div className='main-ronak position-relative'>
                <div className='ronak-hero'>
                    <RonakHero userData={userData} />
                </div>

                <div className="ronak-portfolio">
                    <PortfolioCom
                        heading="Wall of Pride"
                        isTabOn={true}
                        isImageComp={true}
                        isViewBtn={true}
                        // viewurl={`portfolio?type=game_development`}
                        viewurl={`portfolio`}
                        viewButton={'View the entire portfolio'}
                        tabData={tabNameData}
                        typeWise='app_type'
                        isPagination={false}
                        activeTab='Game Development'
                        //portfolios={portFolio}
                    />
                </div>

                <div className="ronak-expertises">
                    <IndustriesCom h1="Expertises" industriesData={ronakExpertisesData} buttonOn={false} bg={true} upperCase={true} />
                </div>

            </div>
        </Layout>
    )
}

export default DevantRupani